import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "./_897485219728.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import new_logo from "./212.png";

function Footer() {
  const history = useNavigate();

  function CalendarNav(e) {
    const date = e.getDate().toString().split("");
    history(
      `/kalendar?date=${e.getFullYear()}-${e.getMonth() + 1}-${
        date.length === 2 ? e.getDate() : "0" + e.getDate()
      }`
    );
  }
  const { t } = useTranslation();
  return (
    <div className=" bg-[#1B6300] dark:bg-[#2F2F2F]  ">
      <div  className="flex flex-col lg:flex-row max-w-[1400px] w-11/12 lg:w-11/12 m-auto py-8 lg:justify-between gap-6">
        <div className="flex flex-col  items-center  gap-4 ">
        <h1 className="text-2xl text-center font-bold  text-white dark:text-white">
        ZENAN.GOV.TM
          </h1>
          <Link to={`/`} className="min-w-max">
            <img src={new_logo} alt="" className="min-w-24  h-24" />
          </Link>
          <h1 className="text-2xl text-center font-bold  text-white dark:text-white">
            {t("main.TÜRKMENISTANYŇ ZENANLAR GURAMASYNYŇ MERKEZI")}
          </h1>
          <p className="text-white/60 text-center">RESMI WEB SAÝTY </p>
        </div>
            {/* <li className="text-[22px] font-semibold">
              {t("main.Soňky habarlar")}
            </li> */}
            <div className="  text-black p-2 flex flex-col items-center ">
              <p className="text-white font-bold text-lg">Arhiw</p>
              <Calendar  calendarType="US" onChange={CalendarNav} />
            </div>
            <ul className="text-white mt-2">
              <li>
                <Link to={'/contact-us'}>
              {t("main.biz barada")}
                </Link>
                </li>
              <li>Boş iş orunlary</li>
              <li>
                <Link to={'/gallery'}>
              {t("main.Multimediýa")}
                </Link>
                </li>
            </ul>
            <div>
            <div className="flex flex-2 flex-col gap-1  text-white">
            <h1 className=" flex gap-2  text-white text-[22px] font-bold font-['DM Sans'] tracking-wide">
              {t("main.Habarlasmak ucin")}
            </h1>
            <h2 className=" flex gap-2 ">
              744000, {t(['main.Aşgabat şäheri, Garaşsyzlyk şaýoly,104'])}
            </h2>
            <a href="tel:448782" className=" flex gap-2">
              {t(['main.Kabulhana'])} (+993)12 448782
            </a>
            <a href="tel:448842" className=" flex gap-2">
              {t(['main.Guramaçylyk bölümi'])} (+993)12 448842
            </a>
            <a href="tel:448756" className=" flex gap-2">
              {t(['main.Faks'])} 448756
            </a>
            tzb@zenan.gov.tm
          </div>

            </div>
          
      </div>
      <div className="text-white  dark:text-white text-xs font-normal text-center bg-[#185601] dark:bg-[#2F2F2F] py-3">
        2023 {t("main.Ähli hukuklar tarapyndan goralan")}
      </div>
    </div>
  );
}
export default Footer;
